<template>
  <div v-if="subscription">
    <ModalDeleteSubscription
      v-if="showDeleteSubscription"
      :showModal="showDeleteSubscription"
      :subscription="subscription"
      :onClickCancel="hideModal"
      @subscriptionDeleted="deleteSubscription"
    />

    <ModalEditSubscription
      v-if="showEditSubscription"
      :showModal="showEditSubscription"
      :onClickCancel="hideModal"
      @subscriptionDeleted="deleteSubscription"
    />

    <nav class="navbar">
      <a @click="$router.go(-1)" class="navbar-item">
        <span class="icon is-small">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </span>
        <span>Back</span>
      </a>
    </nav>

    <ui-page-hero id="id_title_channel" :title="subscription.LocationName" />

    <div
      class="actionBar has-background-white-ter has-padding has-margin-bottom"
    >
      <div class="level">
        <div class="level-left">
          <a
            v-if="!subscription.IsCancelled && subscription.IsRecurring"
            @click="stopSubscription()"
            class="is-small"
          >
            <span class="icon has-text-danger">
              <font-awesome-icon :icon="['fas', 'stop-circle']" />
            </span>
            <span>Stop subscription</span>
          </a>
        </div>
        <div class="level-right">
          <a @click="setShowEditSubscription" class="has-icon">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fa', 'edit']" />
            </span>
            <span>Edit</span>
          </a>
        </div>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth">
              <tbody>
                <tr>
                  <td>Channel</td>
                  <td>{{ subscription.ChannelName }}</td>
                </tr>
                <tr>
                  <td>Package</td>
                  <td>{{ subscription.PackageName }}</td>
                </tr>
                <tr>
                  <td>Period</td>
                  <td>
                    {{ subscription.Month | getMonthName }}
                    {{ subscription.Year }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="column">
            <table class="table is-fullwidth">
              <tbody>
                <tr>
                  <td>Free trial</td>
                  <td class="has-text-right">
                    <span class="has-icon">
                      <span class="icon">
                        <font-awesome-icon
                          :icon="[
                            'fas',
                            subscription.IsTrial ? 'check' : 'times',
                          ]"
                          :class="
                            subscription.IsTrial
                              ? 'has-text-success'
                              : 'has-text-danger'
                          "
                        />
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Recurring</td>
                  <td class="has-text-right">
                    <span class="has-icon">
                      <span class="icon">
                        <font-awesome-icon
                          :icon="[
                            'fas',
                            subscription.IsRecurring ? 'check' : 'times',
                          ]"
                          :class="
                            subscription.IsRecurring
                              ? 'has-text-success'
                              : 'has-text-danger'
                          "
                        />
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Cancelled</td>
                  <td class="has-text-right">
                    <span class="has-icon">
                      <span class="icon">
                        <font-awesome-icon
                          :icon="[
                            'fas',
                            subscription.IsCancelled ? 'check' : 'times',
                          ]"
                          :class="
                            subscription.IsCancelled
                              ? 'has-text-success'
                              : 'has-text-danger'
                          "
                        />
                      </span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Billable</td>
                  <td class="has-text-right">
                    <span class="has-icon">
                      <span class="icon">
                        <font-awesome-icon
                          :icon="[
                            'fas',
                            subscription.SendInvoice ? 'check' : 'times',
                          ]"
                          :class="
                            subscription.SendInvoice
                              ? 'has-text-success'
                              : 'has-text-danger'
                          "
                        />
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td>Monthly fixed fee</td>
              <td class="has-text-right">
                {{ subscription.Price | toCurrency }}
              </td>
            </tr>

            <tr>
              <td>Meetingspace fee</td>
              <td class="has-text-right">
                {{ subscription.SeatFeeMS | toCurrency }}
              </td>
            </tr>
            <tr>
              <td>Meetingspace max. fee seats</td>
              <td class="has-text-right">
                {{ subscription.MaxSeatFeeTotalMS }}
              </td>
            </tr>

            <tr>
              <td>Workspace fee</td>
              <td class="has-text-right">
                {{ subscription.SeatFeeWS | toCurrency }}
              </td>
            </tr>
            <tr>
              <td>Workspace max. fee seats</td>
              <td class="has-text-right">
                {{ subscription.MaxSeatFeeTotalWS }}
              </td>
            </tr>

            <tr>
              <td>Nr. of free seats</td>
              <td class="has-text-right">{{ subscription.NrOfFreeSeats }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import subscriptionProvider from '@/providers/subscription'

const ModalDeleteSubscription = () =>
  import('@/components/Subscriptions/ModalDeleteSubscription')
const ModalEditSubscription = () =>
  import('@/components/Subscriptions/ModalEditSubscription')

export default {
  components: {
    ModalDeleteSubscription,
    ModalEditSubscription,
  },

  data() {
    return {
      showDeleteSubscription: false,
      showEditSubscription: false,
      subscriptionId: Number(this.$route.params.subscriptionId),
    }
  },

  computed: {
    ...mapState('subscriptionStore', [
      'subscription',
      'subscriptionsPageState',
    ]),
  },

  created() {
    this.getSubscription()
  },

  methods: {
    ...mapMutations('subscriptionStore', [
      'setSubscription',
      'setSubscriptionsPageState',
    ]),

    getSubscription() {
      subscriptionProvider.methods
        .getSubscriptionById(this.subscriptionId)
        .then((response) => {
          if (response.status === 200) {
            this.setSubscription(response.data)
          }
        })
    },

    saveSubscription() {
      subscriptionProvider.methods
        .updateSubscription(this.subscription)
        .then((response) => {
          if (response.status === 200) {
            this.setSubscription(response.data)
          }
        })
    },

    stopSubscription() {
      subscriptionProvider.methods
        .stopSubscription(this.subscriptionId)
        .then((response) => {
          if (response.status === 200) {
            this.setSubscription(response.data)
          }
        })
    },

    deleteSubscription(subscription) {
      let index = this.subscriptionsPageState.subscriptions.findIndex(
        (s) => s.Id === subscription.Id
      )
      if (index > -1) {
        this.subscriptionsPageState.subscriptions.splice(index, 1)
        this.setSubscriptionsPageState(this.subscriptionsPageState)
      }

      this.$router.push({ path: `/subscriptions` })
    },

    setShowDeleteSubscription() {
      this.showDeleteSubscription = true
    },

    setShowEditSubscription() {
      this.showEditSubscription = true
    },

    hideModal() {
      this.showDeleteSubscription = false
      this.showEditSubscription = false
    },
  },
}
</script>
